define("discourse/plugins/discourse-landing-pages/discourse/components/page-admin", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/string", "discourse/plugins/discourse-landing-pages/discourse/models/landing-page", "discourse/lib/ajax-error", "I18n"], function (_exports, _component, _object, _decorators, _computed, _string, _landingPage, _ajaxError, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const location = window.location;
  const port = location.port ? ":" + location.port : "";
  const baseUrl = location.protocol + "//" + location.hostname + port;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    updatingPage: (0, _computed.or)("destroyingPage", "savingPage"),
    hasParent: (0, _computed.notEmpty)("parent"),
    updateProps() {
      let props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const pages = props.pages || this.pages;
      this.set("pages", pages);
      this.updatePages(pages);
      let page;
      if (props.page) {
        page = _landingPage.default.create(props.page);
      }
      this.set("page", page);
    },
    showErrorMessage(error) {
      this.set("resultMessage", {
        style: "error",
        icon: "times",
        text: (0, _ajaxError.extractError)(error)
      });
      setTimeout(() => this.set("resultMessage", null), 5000);
    },
    parent(parentId) {
      const parent = this.pages.findBy("id", parentId);
      return parent ? parent : null;
    },
    pagePath(path, parent) {
      return parent ? parent.path : path;
    },
    pageUrl(pagePath) {
      let url = baseUrl;
      if (pagePath) {
        url += `/${(0, _string.dasherize)(pagePath)}`;
      } else {
        url += `/${_I18n.default.t("admin.landing_pages.page.path.placeholder")}`;
      }
      if (this.hasParent) {
        url += `/1`;
      }
      return url;
    },
    onChangePath(path) {
      if (!this.page.parent_id) {
        this.set("page.path", path);
      }
    },
    onChangeParent(pageId) {
      this.set("page.parent_id", pageId);
    },
    createPage() {
      this.updateProps({
        page: {}
      });
    },
    changePage(pageId) {
      if (pageId) {
        _landingPage.default.find(pageId).then(result => this.updateProps(result));
      } else {
        this.updateProps();
      }
    },
    savePage() {
      this.set("savingPage", true);
      this.page.save().then(result => {
        if (result) {
          this.updateProps(result);
        }
      }).catch(error => this.showErrorMessage(error)).finally(() => this.set("savingPage", false));
    },
    destroyPage() {
      this.set("destroyingPage", true);
      this.page.destroy().then(result => {
        if (result.success) {
          this.updateProps(result);
        }
      }).catch(error => this.showErrorMessage(error)).finally(() => this.set("destroyingPage", false));
    },
    exportPage() {
      this.page.export().then(file => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(file);
        link.setAttribute("download", `discourse-${this.page.name.toLowerCase()}.zip`);
        link.click();
      }).catch(error => this.showErrorMessage(error));
    }
  }, [["method", "parent", [(0, _decorators.default)("page.parent_id")]], ["method", "pagePath", [(0, _decorators.default)("page.path", "parent")]], ["method", "pageUrl", [(0, _decorators.default)("pagePath")]], ["method", "onChangePath", [_object.action]], ["method", "onChangeParent", [_object.action]], ["method", "createPage", [_object.action]], ["method", "changePage", [_object.action]], ["method", "savePage", [_object.action]], ["method", "destroyPage", [_object.action]], ["method", "exportPage", [_object.action]]]));
});