define("discourse/plugins/discourse-landing-pages/discourse/components/json-editor", ["exports", "admin/components/ace-editor"], function (_exports, _aceEditor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class JsonEditor extends _aceEditor.default {
    mode = "json";
    htmlPlaceholder = true;
    _overridePlaceholder(loadedAce) {
      const pluginAcePath = "/plugins/discourse-landing-pages/javascripts/ace";
      loadedAce.config.set("modePath", pluginAcePath);
      loadedAce.config.set("workerPath", pluginAcePath);
      loadedAce.config.set("wrap", true);
      super._overridePlaceholder(...arguments);
    }
  }
  _exports.default = JsonEditor;
});